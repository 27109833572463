declare let saveSvgAsPng: any;

export class DownloadHelper {
  static downloadSvgToImg(nativeElement: any, fileName: string): void {
    const htmlElement = nativeElement as HTMLElement;
    const svgElement = htmlElement.cloneNode(true) as HTMLElement;
    svgElement.innerHTML = htmlElement.innerHTML.replace(/ng-reflect-tooltip-value=(["'])(?:(?=(\\?))\2.)*?\1/g, '').trim();
    const { width, height } = nativeElement.getBBox();

    saveSvgAsPng(svgElement, fileName || 'pointee-image.png', {
      scale: 1,
      backgroundColor: 'white',
      height: height + 50,
      width: width + 50,
      excludeUnusedCss: true,
      top: -20,
      left: 8,
    });
  }

  static downloadSvgToImgBlob(nativeElement: any) {
    const htmlElement = nativeElement as HTMLElement;
    const svgBlob = DownloadHelper.svgToBlob(htmlElement);
    const blobURL = URL.createObjectURL(svgBlob);
    const { width, height } = nativeElement.getBBox();
    DownloadHelper.downloadPNGFromAnyImageSrc(blobURL, width, height);
  }

  static downloadPNGFromAnyImageSrc = (src: string, width?: number, height?: number) => {
    //recreate the image with src received
    const image = new Image();
    //when image loaded (to know width and height)
    image.onload = function () {
      //drow image inside a canvas
      const canvas = DownloadHelper.convertImageToCanvas(image, width, height);
      //get image/png from canvas
      const pngImage = DownloadHelper.convertCanvasToImage(canvas);
      //download
      DownloadHelper.download(pngImage.src, 'image.png');
    };
    image.onerror = err => {
      throw new Error(`Could not load ${src}`);
    };
    image.src = src;
  };

  private static svgToBlob(svgElement: any): Blob {
    const clonedSvgElement = svgElement.cloneNode(true) as SVGElement;
    let outerHTML = clonedSvgElement.outerHTML;
    if (outerHTML) {
      outerHTML = outerHTML.replace(/\r?\n|\r/g, '').trim();
    }
    const svgBlob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' });
    return svgBlob;
  }

  private static convertCanvasToImage(canvas: HTMLCanvasElement): HTMLImageElement {
    const image = new Image();
    image.src = canvas.toDataURL('image/png');
    return image;
  }

  private static convertImageToCanvas(image: HTMLImageElement, width?: number, height?: number) {
    const width$ = image.width || width;
    const height$ = image.height || height;
    const canvas = document.createElement('canvas');
    canvas.width = width$;
    canvas.height = height$;
    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    canvas.getContext('2d').drawImage(image, 0, 0, width$, height$);
    return canvas;
  }

  static download(href: string, name: string) {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = name;
    link.setAttribute('type', 'hidden');
    link.style.display = 'none';
    link.href = href;
    link.click();
    link.remove();
  }
}
